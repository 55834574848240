@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

.QuoteCard,
.TipCard {
	@extend .Card;
	@include theme.card-max-width(500px);

	.Container {
		@include theme.flex-column-centered;
		gap: theme.$cardContentGap;
	}
}

.Text {
	@include theme.text-gen(1.1rem, 600);
	text-align: center;
}

.TipCard .Text {
	text-align: justify;
	margin: unset;
	ol {
		padding: 2.5%;
	}
}

.Separator {
	width: 100px;
	height: 1px;
	border: none;
	background-color: theme.$secondaryLight;
	opacity: 0.5;
	margin: theme.$cardContentGap auto;
	@media (prefers-color-scheme: dark) {
		background-color: theme.$white;
	}
}

.QuoteSource {
	@include theme.flex-column-centered;
	display: inline;
	text-align: center;
	@include theme.text-gen(0.9rem, 600);
	& strong {
		display: block;
		text-align: center;
		@include theme.title-gen(1.1rem);
	}
}
