@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

.Header {
	background-color: theme.$primaryGradient;
}

.ErrorCard {
	@extend .Card;
	@include theme.card-max-width(380px);
	width: calc(100% - 40px);

	.Content {
		@include theme.flex-column-centered;
		justify-content: flex-start;
		text-align: center;
		font-size: 56px;
		gap: theme.$cardContentGap;

		.ErrorCode {
			@include theme.title-gen(2.5rem);
		}
		
		.ErrorSubtitle {
			@include theme.text-gen(3rem);
			font-family: theme.$secondaryFont;
			background: theme.$primaryGradient;
			background-clip: text;
			-webkit-text-fill-color: transparent;
			padding: 11px; // necessary to avoid undesired cropping with certain fonts (eg: Bernadette)
		}

		.ErrorText {
			@include theme.text-gen(1rem);
			font-family: theme.$mainFont;
			white-space: pre-line;
		}
	}
}
