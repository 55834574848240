@use 'src/theme.scss' as theme;
.Form{
    // height: 100%;
}
.CardContent {
    @include theme.flex-column;
    gap: theme.$cardContentGap;
}

.StepImage {
    max-width: 100%;
    width: auto;
    max-height: min(20vh, 100px);
    height: auto;
    align-self: center;
}

// .ScrollableArea {
// 	@include theme.scroll-bar(50vh);
// 	display: block;
// 	@include theme.large-screen {
// 		@include theme.scroll-bar(200px);
// 	}
// 	.Content {
// 		padding: 5px 10px;
// 	}
// 	p {
// 		text-align: center;
// 		margin: 0;
// 		line-height: 1.5rem;
// 		&:not(:first-of-type) {
// 			margin-top: 2rem;
// 		}
// 	}
// }


///////////////////////////
// WELCOME CARD
///////////////////////////

.ReceptionTimeContainer {
    @include theme.flex-row-centered;
    @include theme.text-gen(1.25rem);
	gap: theme.$cardContentGap;
	align-self: center;
    align-items: flex-start;
    justify-content: space-evenly;
    width: min(100%, 250px);
    color: theme.$black;

    .DaysContainer,
    .HourContainer {
        @include theme.flex-column-centered;
        width: 50%;

        .Icon {
            width: auto;
            height: min(5vh, 50px);
            margin-bottom: 1rem;
        }
    }
}


///////////////////////////
// TERMS OF SERVICE CARD
///////////////////////////

.TOSContent {
    @include theme.flex-column;
    max-width: 500px;
    // min-width: 280px;
    // width: 90%;
    gap: 5px;
}


///////////////////////////
// FORM CARD
///////////////////////////

.PhoneField { // overrides TelInput default styling
    input {
        width: 100%
    }
}


///////////////////////////
// THANK YOU CARD
///////////////////////////

.ThankYouCard {
	@include theme.flex-column-centered;
    gap: theme.$cardContentGap;

	.ThankYouContainer {
		@include theme.flex-column-centered;
	}

    .ButtonsContainer {
		@include theme.flex-column-centered;
        gap: theme.$cardContentGap;
        align-self: center;
	}

    .ThankYou {
        @include theme.title-gen(4rem);
        @include theme.gradient-text-gen(theme.$secondaryGradient);
    }

    .ThankYouText {
        max-width: 100%
    }

    .SubscriberName {
        @include theme.title-gen(4rem);
        font-family: theme.$secondaryFont;
        @include theme.gradient-text-gen(theme.$primaryGradient);
        padding: 20px;
        max-width: 100%;
        text-align: center; // for longer names
        margin: 0 10px 0 20px; // to prevent optical illusion that the name is not centered due to the exclamation point
    }

    .FirstVideoContainer {
        @include theme.flex-column-centered;
        & > * {
            margin: theme.$elementSmallPadding auto;
        }
    }
    
    .Text {
        @include theme.text-gen(1rem);
    }
    
    .GradientText {
        @include theme.text-gen(1rem, 700);
        @include theme.gradient-text-gen(theme.$primaryGradient); 
    }

    .Icon {
        width: auto;
        height: min(8vmin, 65px);
    }

    .Link {
        @include theme.title-gen(1rem);
        @include theme.gradient-text-gen(theme.$secondaryGradient);
    }
}