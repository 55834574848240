@use 'src/theme.scss' as theme;
@use 'src/components/Common/Card.module.scss';

$horizontalSize: 28%;
$numberContainerSize: 3rem;

.StepperCard {
	@extend .Card;
	@include theme.flex-column-centered;
	@include theme.card-max-width(theme.$extraLargeCardWidth);
	@include theme.text-gen(1rem);
	box-sizing: border-box;
	padding: 0;
	text-align: justify;
	align-items: stretch;
	justify-content: space-between;
	height: auto;

	p {
		align-self: flex-start;
	}

	& > :nth-child(2) {
		@include theme.flex-column-centered;
		// 1st child is the stepper, 2nd child is the children. This targets all the card content
		margin: theme.$cardSpacing;
		min-height: fit-content;
		@include theme.small-screen {
			margin: theme.$smallCardSpacing;
		}
	}

	@include theme.medium-screen {
		min-width: unset;
	}

	@include theme.large-screen {
		min-height: 10vh;
		flex-direction: row;
		justify-content: space-between;
		padding: theme.$cardSpacing;
		max-height: 80vh;
		& > :nth-child(2) {
			width: 100% - $horizontalSize;
			margin-left: theme.$cardSpacing; // since Stepper card is not built like other cards, we need to add the margin that can't be applied through the default Card styling
			margin-top: 0;
			margin-bottom: 0;
			margin-right: 0;
		}
	}

	.StepContentContainer {
		max-width: 100%;
		// @include theme.large-screen {
		// 	// height: fit-content;
		// }

		> div {
			gap: theme.$cardContentGap;
			@include theme.flex-column;
		}
	}
}

.Stepper {
	min-height: 20px;
	border-radius: theme.$cardCornerRadius theme.$cardCornerRadius 0 0;
	background: theme.$cardHeaderBackground;
	box-sizing: border-box;
	padding: theme.$cardSpacing;
	@include theme.flex-row-centered;
	align-items: flex-start;
	text-align: center;
	background: theme.$grayDark;
	justify-content: space-between;
	gap: 5px;

	@include theme.large-screen {
		@include theme.flex-column-left;
		justify-content: center;
		align-items: baseline;
		box-sizing: content-box;
		margin: theme.negative-value(theme.$cardSpacing);
		margin-right: 0px;
		width: $horizontalSize;
		border-radius: theme.$cardCornerRadius 0 0 theme.$cardCornerRadius;
		gap: 20px;
	}
	@include theme.small-screen {
		padding: theme.$smallCardSpacing;
	}
	@media (prefers-color-scheme: dark){
		background: theme.$dmColorZ2;
	}
}

.SingleStep {
	@include theme.flex-column-centered;
	@include theme.title-gen(2rem);
	justify-content: flex-start;
	color: theme.$black;
	gap: 10px;
	max-height: 7rem;
	width: 25%;
	height: 100%;

	@include theme.large-screen {
		@include theme.flex-row-left;
		flex: 0;
		min-width: fit-content;
		width: 100%;
	}
}

.NumberContainer {
	@include theme.flex-column-centered;
	justify-self: flex-start;
	@include theme.text-gen(1.5rem, 600);
	border: 2px solid theme.$white;
	border-radius: 50%;
	min-width: $numberContainerSize;
	max-width: $numberContainerSize;
	min-height: $numberContainerSize;
	max-height: $numberContainerSize;
	background: theme.$secondaryGradient;
	background-clip: content-box;
	-webkit-text-fill-color: transparent;
	z-index: 1;
	position: relative;
	transform: scale(0.8);
	@media (prefers-color-scheme: dark){
		background: theme.$dmColorZ4;
	}

	.Number {
		-webkit-text-fill-color: initial; // used to override fill-color: transparent used to set gradient background
		color: theme.$white;
	}

	&.Active {
		background: theme.$white;
		background-clip: content-box;
		-webkit-text-fill-color: transparent;
	}

	&.Active > .Number {
		background-clip: content-box;
		-webkit-text-fill-color: initial; // used to override fill-color: transparent used to set gradient background
		color: theme.$mainColor; // don't know how to use a gradient here. We should use webkit fill color: transparent, but it is creating a conflict with the fact that the background uses it...
	}

	&.Current {
		background: theme.$white;
		background-clip: content-box;
		-webkit-text-fill-color: transparent;
		transform: scale(1);
		border: none;
	}

	&.Current > .Number {
		background-clip: content-box;
		-webkit-text-fill-color: initial; // used to override fill-color: transparent used to set gradient background
		color: theme.$mainColor; // don't know how to use a gradient here. We should use webkit fill color: transparent, but it is creating a conflict with the fact that the background uses it...
		transform: scale(1);
	}
}

.LegendContainer {
	@include theme.flex-row;
}

.Legend {
	@include theme.title-gen(1.1rem, 300);
	transform-origin: left;
	transform: scale(0.8);
	filter: opacity(0.8);
	width: 100%;
	text-align: center;
	letter-spacing: 0.05rem;
	&.Active {
		color: theme.$black;
	}

	&.Current {
		color: theme.$black;
		transform-origin: top;
		transform: scale(1);
		filter: opacity(1);
	}

	@include theme.large-screen {
		text-align: left;
	}

	@include theme.medium-screen {
		transform-origin: top;
		word-break: break-word;
	}
	@include theme.small-screen {
		display: none;
	}
}

.Line {
	display: none;
}
