@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

.VoteResultsCard {
  @extend .Card;
  @include theme.card-max-width(theme.$smallCardWidth);
  color: theme.$black;
  @media (prefers-color-scheme: dark){
    color: theme.$white;
  }

  .VoteResultsContainer {
    @include theme.flex-column-centered;
    gap: theme.$cardContentGap;
  }

  h2 {
    text-align: center;
  }
  
  h4 {
    text-align: center;
    color: theme.$black;
    text-align: left;
    @media (prefers-color-scheme: dark){
      color: theme.$white;
    }
  }

  .Results {
    width: 100%;
  }

  .Result {
    margin-bottom: theme.$cardContentGap;
    width: 100%;
  }

  .Label {
    @include theme.text-gen(0.9rem, 600);
    margin: 0 0 5px;
  }

  .ProgressBar {
    margin-right: 10px;
    border-radius: 0 50px 50px 0;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    max-width: 80%;
  }

  .others {
    color: theme.$primaryDark;
    background-image: theme.$primaryGradient;
  }

  .company {
    color: theme.$secondaryDark;
    background-image: theme.$secondaryGradient;
  }

  .Value {
    @include theme.text-gen(0.9rem, 700);
    vertical-align: middle;
    color: theme.grey();
    @media (prefers-color-scheme: dark){
      color: theme.$white;
    }
  }

  .Legend {
    @include theme.flex-row;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    list-style: none;

    & > li {
      &:not(:last-of-type) {
        margin: 0;
        margin-right: 20px;
      }

      span {
        white-space: nowrap;
      }

      .ProgressBar {
        min-width: 32px;
      }

      .Value {
        @include theme.title;
        font-size: 1rem;
        color: theme.$black;
        @media (prefers-color-scheme: dark){
          color: theme.$white;
        }
      }
    }
  }

  p {
    @include theme.text-gen(0.9rem);
    text-align: center;
    strong {
      @supports (background-clip: text) {
        background-clip: text;
        color: transparent;
      }
      @media screen and (min-width: 0\0) {
        /* IE only */
        background-image: none;
      }
    }
  }
}
