@use 'src/theme.scss' as theme;
@import 'src/components/Common/Card.module.scss';

.TranscriptCard {
    @extend .Card;
    @include theme.card-max-width(theme.$mediumCardWidth);

    > div {
        @include theme.flex-column-centered;
        gap: theme.$cardContentGap;
    }
}

.TranscriptTitle {
    @include theme.title-gen(2rem);
    @include theme.gradient-text-gen(theme.$primaryGradient);
    text-align: center;
}

.TitleContainer {
    @include theme.flex-row-centered;
    height: min-content;
    margin-bottom: 10px;
}

.Line {
    height: 1px;
    flex-grow: 1;
    background: linear-gradient(
        90deg,
        rgba(theme.$primaryDark, 1) -3%,
        rgba(theme.$primaryLight, 1) 103%
    );
}

.Title {
	@include theme.title-gen(1.6rem);
	margin-right: 10px;
    @include theme.gradient-text-gen(theme.$primaryGradient);
}

.SectionContent {
    $sectionWidth: 100%;
    $timecodeWidth: 15%;
    $textWidth: 85%;

    @include theme.flex-row-left;
    @include theme.text(1rem);
    color: theme.$black;
    width: $sectionWidth;
    & > * {
        margin: 5px 0;
    }

    .Timecode {
		width: $timecodeWidth;
        align-self: flex-start;
    }
    
    .Text {
        width: $textWidth;
    }
}