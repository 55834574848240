@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

.UnsubCard {
	@extend .Card;
    max-width: unset;
}

.UnsubParagraph {
    @include theme.flex-column-left;
    gap: theme.$cardContentGap;
    max-width: 400px;
    
    a {
        font-size: 0.9rem;
    }
}

.UnsubContainer {
    @include theme.flex-column-centered;
    gap: theme.$cardContentGap;
}