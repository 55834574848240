@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

.TopicCard {
	@extend .Card;
	@include theme.flex-column-centered;
	@include theme.card-max-width(500px);

	.TopicCardContainer {
		@include theme.flex-column-centered;
		gap: theme.$cardContentGap;
	}

	.Content {
		@include theme.flex-column-centered;
		@include theme.text-gen(1rem);
		text-align: justify;
		gap: theme.$cardContentGap;
	}

	// ul, ol { Not sure we want that, keeping it before we validate on chromatic
	//   > * {
	//     text-align: left;
	//   }
	// }

	// em {
	//   // once sources (e.g.: studies) are refactored and have their own styling, we will use em to target specific elements and use a color on them
	//   font-weight: 700;
	//   font-style: normal; // to override default italics
	//   color: theme.$primaryDark;
	//   @supports (background-clip: text) {
	//     background: theme.$primaryGradient;
	//     background-clip: text;
	//     color: transparent;
	//   }
	// }
}
