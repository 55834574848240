@use "src/theme.scss" as theme;

.Share {
  @include theme.text-gen(0.75rem, 600);
  text-align: center;
  margin-bottom: 5px;
}

.Actions {
  text-align: center;

  & img {
    margin: 5px;
    cursor: pointer;
  }
}
