@use "src/theme.scss" as theme;

@font-face {
    font-family: 'Montserrat';
    src: url('./static/fonts/Montserrat-Italic.woff2') format('woff2'),
        url('./static/fonts/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./static/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('./static/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./static/fonts/Montserrat-Bold.woff2') format('woff2'),
        url('./static/fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html {
	overflow-x: hidden;
	background: theme.$black;
	scroll-behavior: smooth;
	@media (prefers-reduced-motion: reduce) {
		scroll-behavior: auto;
	}
	@include theme.large-screen {
		font-size: 16px;
	}
	@include theme.medium-screen {
		font-size: 14px;
	}
}

body {
	margin: 0;
	min-width: 320px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	user-select: none;
	@include theme.text-gen(1rem, 500);
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}

h1,
h2,
h3,
h4,
h5,
p {
	margin: unset;
}

h1 {
	@include theme.title-gen(3rem);
	font-family: theme.$titleFont;
	text-align: center;
	color: theme.$secondaryDark;
	font-weight: bold;
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
	
}

h2 {
	@include theme.title-gen(1.7rem);
	color: theme.$black;
	font-weight: bold;
	text-align: center;
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}

h3 {
	@include theme.title-gen(1.5rem);
	color: theme.$black;
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}

h4 {
	@include theme.title-gen(1.3rem);
	color: theme.$black;
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}

a{
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}
