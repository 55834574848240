@use "src/theme.scss" as theme;


.VoteContainer {
  @include theme.flex-column-centered;
  justify-content: space-evenly;
  width: theme.$playerWidth;
  height: theme.$playerHeight;
  border-radius: 25px 25px 0 0;
  overflow: hidden;
  user-select: none;
  position: absolute;
  top: 0;
  z-index: 5;
}

.VoteContainer > div {
  width: 100%;
}

.QuestionContainer {
  @include theme.flex-column-centered;
  justify-content: flex-end;
  margin-top: 20%;
  padding: 10px auto;
  max-height: 40%;

  // The question is being displayed in the video's background, so we don't show it, but we keep it in the DOM for layout reasons
  visibility: hidden;
}


.QuestionText {
  position: relative;
  @include theme.title;
  text-align: center;
  padding: 10px 40px;
  color: theme.$white;
}

.AnswerContainer {
  @include theme.flex-column-centered;
  height:50%;
  margin-bottom: 90px;
}

.AnswerContainer>* {
  margin: 1vh 0;
  width: 100%;
}

.VoteButton {
	@include theme.text;
  color: theme.$white !important; // Because this will always be displayed over a yellow background, always show it in black 
	font-size: 1rem;
  position: relative;
  border: 1px solid theme.$white;
  outline: none;
  border-radius: 200px;
  text-align: center;
  padding: 1vh 1.5vh;
  margin: 0 auto;
  cursor: pointer;
  display: block;
  width: 90%;
  background: transparent;
  transition: background .3s;
  // height: fit-content;
  
  &:hover {
    // note: couldn't find how to use alpha/transparency with a variable for the gradient part
    background:rgba($color: theme.$white, $alpha: 0.3);
    // box-shadow: 0px 0px 12px theme.$white;
  }

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   // height: inherit;
  //   width: 100%;
  //   transform: translate(-4px, -3px);
  //   transition: transform 0.2s;
  //   border-radius: inherit;
  //   // box-sizing: border-box;
  //   // display: inline-block;
  //   Container-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  //   background-image: url("../src/static/img/cta.svg");
  // }
  
  // &:hover:before {
  //   transform: translate(4px, 3px);
  //   transition-delay: 0.1s;
  // }
  // &:active:before,
  // :focus:before {
  //   transform: translate(0, 0);
  //   transition-delay: 0s;
  // }
}


.Clicked, .Clicked:hover {
  background: theme.$primaryGradient;
}

.playkit-playback-controls.playkit-center-playback-controls {
  display: none !important;
}