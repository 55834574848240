@use 'src/theme.scss' as theme;
@import 'src/components/Common/Card.module.scss';

.LinkCard {
	@extend .Card;
	cursor: pointer;
}

.ListsContainer {
    @include theme.flex-column-centered;
    gap: theme.$cardContentGap;
	 width: 100%;
}

.contentList {
	display: flex;
	flex-direction: column;
	width: 100%;
	&:not(:last-of-type) {
		margin-bottom: 30px;
	}
}

.listHeader {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
}

.label {
	@include theme.title;
	font-size: 1.6rem !important;
	margin-right: 10px;
	color: theme.$primaryDark;
}

.line {
	height: 1px;
	flex-grow: 1;
	background: linear-gradient(
		90deg,
		theme.$primaryDark -3%,
		theme.$primaryLight 103%
	);
	@media (prefers-color-scheme: dark){
		background: theme.$white;
	}
}

.list {
	display: grid;
	grid: auto-flow / 1fr 1fr;
	// Remove columns as we go down in screen sizes
	&:not(.grid_max_2) {
			@include theme.large-screen {
			grid: auto-flow / 1fr 1fr 1fr;
		}
	}

	@include theme.medium-screen {
		grid: auto-flow / 1fr;
	}

	@include theme.small-screen {
		grid: auto-flow / 1fr;
	}
	gap: 20px;
}

.thumbnailTile {
	background-color: theme.$white;
	border-radius: theme.$cardCornerRadius;
	box-shadow: theme.$lightShadow;
	display: flex;
	align-items: center;
	justify-content: stretch;
	color: currentColor;
	text-decoration: none;
	width: 100%;
	// height: 100%; Causes issues on Safari as of June 2022
	@media (prefers-color-scheme: dark){
		background: theme.$dmColorZ2;
	}

	&:hover {
		position: relative;
		box-shadow: theme.$shadow;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			background-image: url('/static/img/border-gradient-primary.svg');
		}
	}

	> img {
		width: 113px;
		height: 100%;
		min-height: 113px;
		object-fit: cover;
		border-top-left-radius: theme.$cardCornerRadius;
		border-bottom-left-radius: theme.$cardCornerRadius;
	}
	&.left_fill_image img {
		object-position: right;
	}


	.content {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		min-height: 90px;
		margin: 0 theme.$cardContentGap;
		gap: 10px;
		width: 90%;
		height: 100%;

		p {
			@include theme.text;
			$pFontSize : 1rem;
			text-align: left;
			font-size: $pFontSize;
			color: theme.grey();
			margin: 0;
			display: -webkit-box;
			$linesToClamp: 3;
			$lineHeight: 1; // rem
			max-height: $linesToClamp * $lineHeight +rem;
			line-height: $lineHeight +rem;
			-webkit-line-clamp: $linesToClamp;
			-webkit-box-orient: vertical;
			overflow: hidden;
			flex-grow: 1;
		}

		.title {
			@include theme.text-gen(1rem, 700);
			margin: 5px 0;
			color: theme.$black;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.meta {
			@include theme.text;
			font-size: 0.75rem;
			font-weight: 400;
			color: theme.grey();
			display: flex;
			align-items: flex-end;
			flex-grow: 0;
			justify-content: space-between;

			&:last-child {
				margin-bottom: 5px;
			}

			.provider {
				@include theme.text-gen(0.75rem, 400);
				display: inline-flex;
				align-items: flex-end;
				img {
					width: 16px;
					height: 16px;
					object-fit: cover;
					margin-right: 0.5em;
					@include theme.small-screen {
						display: none;
					}
				}
			}

			.date {
				white-space: nowrap;
				&::first-letter {
					text-transform: uppercase;
				}
			}
		}

		.type {
			display: inline-block;
			background-image: theme.$secondaryGradient;
			opacity: 0.9;
			@include theme.text-gen(0.65rem, 500);
			color: theme.$white;
			margin-bottom: 5px;
			max-width: max-content;
			border-radius: 2px;
			padding: 0 8px;
			@media (prefers-color-scheme: dark){
				background: theme.$dmColorZ4;
			}
		}
	}

	.arrow {
		color: theme.$primaryDark;
		margin-right: 10px;
		flex-shrink: 0;
		display: none;
	}

	@include theme.large-screen {
		&:not(.left_fill_image) { 
			flex-direction: column;
			> img {
				width: 100%;
				height: 100px;
				border-top-right-radius: theme.$cardCornerRadius;
				border-bottom-left-radius: 0;
				margin-right: 0;
			}
		}

		&.left_fill_image { 
			flex-direction: row;
			> img {
				width: 150px;
				height: 150px;
				min-height: 100%;
				margin-right: 0;
			}
		}

		> img + .content .info {
			margin-top: calc(-1.2em - 5px);
		}

		.content {
			margin: 5px 10px;
			.info {
				order: -1;
				.meta {
					flex-direction: column;
					align-items: flex-start;
					.provider {
						color: theme.$white;
						background-color: theme.grey(0.85);
						color: theme.$white;
						border-radius: 3px;
						padding: 4px 10px;
						img {
							border-top-left-radius: 3px;
							border-bottom-left-radius: 3px;
						}
						&:after {
							content: ' ';
						}
					}
					.date {
						margin-top: 5px;
					}
				}
				.type {
					margin-top: -1.2em;
					margin-bottom: 0;
					float: right;
				}
			}
			p {
				margin-bottom: 5px;
			}
		}
		.arrow {
			display: none;
		}
	}
}


.hide_small {
	@include theme.small-screen {
		display: none;
	}
}