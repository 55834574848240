@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

.BiographyCard {
	@extend .Card;
	@include theme.card-max-width(1150px);

	@include theme.medium-screen {
		@include theme.card-max-width(650px);
		gap: theme.$cardContentGap;
	}

	@include theme.large-screen {
		margin-top: theme.$cardContentGap;
		padding: 0;
		flex-direction: row;
		align-items: stretch;

		& > header {
			display: none;
		}

		& > * {
			padding: theme.$cardSpacing;
		}
	}
}

.PersonInfo {
	position: relative;
	text-align: center;
	margin: 0 auto;
	min-width: 200px;
	width: 15vw;
	flex-shrink: 0;
	margin-top: theme.negative-value(
		theme.$cardContentGap
	); // important: needed because this card is not built like other cards, grid-gap is applied above the person info. This "cancels" it.
	white-space: pre-line;

	@include theme.large-screen {
		@include theme.flex-column-centered;
		max-width: 150px;
		background: theme.$grayDark;
		color: theme.$black;
		border-radius: theme.$cardCornerRadius 0 0 theme.$cardCornerRadius;
		margin-top: unset;
		@media (prefers-color-scheme: dark) {
			background: theme.$dmColorZ2;
		}
	}

	.Avatar {
		width: 150px;
		height: 150px;
		border: 2px solid theme.$white;
		border-radius: 50%;
		background-color: theme.$white;
		@include theme.small-screen {
			position: absolute;
			top: -58px;
			left: calc(50% - 38px);
			width: 76px;
			height: 76px;
		}
	}

	.Name {
		@include theme.title-gen(2rem);
		font-family: theme.$titleFont;
		line-height: 2.5rem;
		margin: 20px 0 2px;
		text-align: inherit;
		@include theme.medium-screen {
			margin: 30px 0 2px;
		}
	}

	.Title {
		@include theme.text;
		margin: 6px 0 2px;
		text-align: inherit;
	}

	.Company {
		@include theme.text-gen(1rem, 700);
		text-align: inherit;
		margin: 6px 0;
	}
}

.Content {
	@include theme.text;
	// @include theme.scroll-bar(234px); Removed per Floriane Aug 30th 2022
	p,
	ul {
		margin: theme.$cardContentGap 0;
		&:first-child {
			margin-top: 0;
		}
	}

	@include theme.large-screen {
		line-height: 1.25rem;
		// @include theme.scroll-bar(220px); Removed per Floriane Aug 30th 2022
	}
}

.SocialProfiles {
	@include theme.flex-row-centered;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 50px;

	img {
		padding: 0 1rem;
		@include theme.large-screen {
			justify-self: start;
			padding: 0 2rem;
		}
		height: max(50px, 5vmin);
		width: max(50px, 5vmin);
	}
}
