@use "theme.scss" as theme;

$animationDuration: .5s;
// Export this duration to be used in Switch JS
:export { animationDurationInSeconds: $animationDuration; }

.SwitchCard {
  width: theme.$playerWidth;
  transition: transform $animationDuration ease-in-out;
  transform-style: preserve-3d;
}

.Switched {
  transform-origin: center right;
  .Front {
    position: absolute;
    transform: rotateY(-180deg);
  }
  .Back {      
    position: unset;
    transform: rotateY(0deg);
  }
}

.Front, .Back {
  @include theme.flex-column-centered;
  transition: transform $animationDuration ease-in-out;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
}

.Back {
  position: absolute;
  transform: rotateY(-180deg);
}