@use 'src/theme.scss' as theme;
@use 'src/components/Common/Card.module.scss';

$formItemBorderRadius: 10px;
$formItemWidth: 90%;

.CardContent {
	@extend .Card;
	@include theme.flex-column;
	min-width: 100%
}

.Form {
	@include theme.flex-column-centered;
	align-self: center;
	gap: 15px;
	@include theme.medium-screen {
		min-width: unset;
	}

	// @TODO remove once Card stops setting styles to all children
	:global(.iti__country-list) {
		text-align: left;
	}
	// @TODO remove once Card stops setting styles to all children
	:global(.iti__hide) {
		display: none;
	}

	button {
		align-self: center;
	}
}

legend {
	width: $formItemWidth;
}

.FormCheckBox,
.FormRadio {
	$labelSpacing: 10px;
	$inputSize: 1.2rem;

	@include theme.flex-row;
	align-items: center;
	color: theme.$mainColor;
	gap: $labelSpacing;

	.Input {
		width: $inputSize;
		height: $inputSize;
		margin: 0;
		accent-color: theme.$mainColor;

		&:focus {
			@include theme.shadow-focus();
		}

		&[type='radio'] {
			position: relative;
			&:focus {
				box-shadow: none;
				& + .Label::before {
					// fix the radio squared shadow https://stackoverflow.com/questions/48062554/css-radio-button-and-text-outline
					content: '';
					width: $inputSize;
					height: $inputSize;
					display: inline-block;
					position: absolute;
					transform: translateX(calc(-1 * $labelSpacing - $inputSize));
					border-radius: 50%;
					@include theme.shadow-focus();
				}
			}
		}
	}
}

.FormSelect {
	@include theme.flex-column;
	gap: 5px;
	width: -webkit-fill-available;
	min-width: min(350px, 100%);

	select {
		color: theme.$black;
		background-color: transparent;
		border-radius: $formItemBorderRadius;
		border: 2px solid theme.$primaryDark;
		font-size: inherit;
		padding: 0.5rem;
		box-sizing: border-box;
		min-height: 40px;
		@media (prefers-color-scheme: dark){
			color:theme.$white;
			border-color: theme.$dmColorZ4;
		}
		option{
			@media (prefers-color-scheme: dark){
				background-color: theme.$dmColorZ4;
			}
		}
		&:focus {
			@include theme.shadow-focus();
		}
	}
}

.FormField {
	@include theme.flex-column;
	width: $formItemWidth;
	width: -webkit-fill-available;
	gap: 5px;
	min-width: min(350px, 100%);

	.Input {
		border-radius: $formItemBorderRadius;
		border: 2px solid theme.$primaryDark;
		font-size: inherit;
		font-family: theme.$mainFont;
		background-color: transparent;
		padding: 0.5rem;
		box-sizing: border-box;
		min-height: 40px;
		@media (prefers-color-scheme: dark){
			border-color: theme.$dmColorZ4;
		}

		&::placeholder {
			opacity: 0.7;
			font-style: italic;
			padding-left: 5px;
		}

		&:focus {
			@include theme.shadow-focus();
		}

		// &:autofill,
		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0px 1000px white inset;
		}
		@media (prefers-color-scheme: dark){
			color: theme.$white;
		}
	}

	// iti__selected-flag
	[role='combobox']:focus-visible {
		border-radius: $formItemBorderRadius;
	}
}

.Label {
	@include theme.title-gen(1rem);
	color: theme.$black;
	cursor: pointer;
	z-index: 1; // Needed to 'pop' the label on top of the border of the element beneath it
	width: fit-content;
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}

.ErrorText {
	color: theme.$errorColor;
	margin: 0.5rem 0;
}

.FormField,
.FormSelect {
	margin-top: -0.7rem;
	width: $formItemWidth;
	.Label {
		padding: 0 0.3rem;
		margin-right: calc($formItemBorderRadius + 0.75rem);
		transform: translate($formItemBorderRadius, 0.8rem);
		background: theme.$white;
		@media (prefers-color-scheme: dark){
			background: theme.$dmColorZ1;
		}
	}
}

.FieldError {
	.Input {
		border-color: theme.$errorColor;
	}
	.Label {
		color: theme.$errorColor;
	}
}

.FieldLoading {
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 1em;
		height: 1em;
		top: calc(0.5em + 1.5rem);
		right: calc(1.2em - $formItemBorderRadius);
		border-radius: 50%;
		border: 3px solid;
		border-color: theme.$mainColor transparent;
		opacity: 0.5;
		animation: Loading 1.2s linear infinite;
	}

	.Input {
		padding-right: 2em;
	}
}

@keyframes Loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
