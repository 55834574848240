@use "src/theme.scss" as theme;

.Card {
	@include theme.card-max-width;
	@include theme.flex-column-centered;
	box-sizing: border-box;
	background-color: theme.$cardContentBackground;
	// color: theme.$black;
	border-radius: theme.$cardCornerRadius;
	box-shadow: theme.$cardShadow;
	padding: theme.$cardSpacing; // Note: For cards with a header, header has a margin-bottom of the same size, to make sure the gutter / padding is uniform on top / bottom / sides
	min-width: min-content;
	height: fit-content;
	margin-top: 2.5vmin; // separate cards from each other when there are several
	margin-bottom: 2.5vmin; // separate cards from each other when there are several
	margin-left: 2vmin; // create space between card and the border of the screen
	margin-right: 2vmin; // create space between card and the border of the screen
	@include theme.small-screen {
		padding: theme.$smallCardSpacing;
	}
	@media (prefers-color-scheme: dark){
		background-color: theme.$dmColorZ1;
	}

	ol {
		@include theme.flex-column;
		
		align-items: left;
		list-style: none;
		counter-reset: li;
		li {
			counter-increment: li;
			margin-bottom: 10px;
			padding-right: 10px;
			min-width: 60%;
			&::before {
				@include theme.text-gen(0.9rem, 600);
				content: counter(li) '.';
				display: inline-block;
				margin-right: 0.5em;
				color: theme.$secondaryLight;
				@supports (background-clip: text) {
					background: theme.$secondaryGradient;
					background-clip: text;
					color: transparent;
				}
				@media (prefers-color-scheme: dark){
					color: theme.$white;
				  }
			}
		}
	}

	ul {
		@include theme.flex-column;
		align-items: left;
		list-style: none;
		text-align: center;
		padding-left: 0;
		li {
			line-height: 1.5rem;
		}
	}

	p {
		@include theme.text-gen(1rem);
		text-align: justify;
	}

	legend {
		@include theme.text-gen(0.9rem);
	}
}

.Header {
	height: fit-content;
	min-height: 20px;
	width: calc(100% + calc(theme.$cardSpacing * 2));
	margin: theme.negative-value(theme.$cardSpacing) theme.negative-value(theme.$cardSpacing) 0px;
	margin-bottom: theme.$cardSpacing; // Note: this is to make sure cards with a header have the same gutter between the top of the card and its content, and the other sides
	border-radius: theme.$cardCornerRadius theme.$cardCornerRadius 0 0;
	background: theme.$cardHeaderBackground;
	@include theme.title-gen(1.5rem);
	color: theme.$white;
	@include theme.small-screen {
		width: calc(100% + calc(theme.$smallCardSpacing * 2));
		margin: theme.negative-value(theme.$smallCardSpacing) theme.negative-value(theme.$smallCardSpacing) 0px;
		margin-bottom: theme.$smallCardSpacing; // Note: this is to make sure cards with a header have the same gutter between the top of the card and its content, and the other sides
	}
	// & > * {
		// if you put something in the header, make sure it has enough padding
		// padding: min(2vh, 2vw);
	// }
	@media (prefers-color-scheme: dark){
		background: theme.$dmColorZ2;
	}
}
