@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

@mixin ActionIcon {
	height: max(10%, 48px);
	width: max(10%, 48px);
}

@keyframes rotateClockwise {
	0% {
		transform: rotateZ(0deg) scale(1);
	}
	50% {
		transform: rotateZ(10deg) scale(1.1);
	}
	100% {
		transform: rotateZ(0deg) scale(1);
	}
}
@keyframes rotateCounterClockwise {
	0% {
		transform: rotateZ(0deg) scale(1);
	}
	50% {
		transform: rotateZ(-10deg) scale(1.1);
	}
	100% {
		transform: rotateZ(0deg) scale(1);
	}
}
@keyframes rotateBadButtonSelected {
	0% {
		transform: rotate(0) scale(1);
	}
	20% {
		transform: rotate(-5deg) scale(1);
	}
	50% {
		transform: rotate(20deg) scale(1);
	}
	100% {
		transform: rotate(0) scale(1);
	}
}

@keyframes rotateGoodButtonSelected {
	0% {
		transform: rotate(0) scale(1);
	}
	20% {
		transform: rotate(5deg) scale(1);
	}
	50% {
		transform: rotate(-20deg) scale(1);
	}
	100% {
		transform: rotate(0) scale(1);
	}
}

.Landing {
	@extend .Card;
	@include theme.card-max-width(theme.$largeCardWidth);
	width: calc(100% - 40px);

	.Content {
		@include theme.flex-row-centered;
		gap: theme.$cardContentGap;
		justify-content: space-between;
		width: 100%;
		@include theme.small-screen {
			@include theme.flex-column-centered;
			align-items: stretch;
		}
	}

	header {
		@include theme.extra-small-screen {
			display: none;
		}
	}

	.WelcomeBack {
		@include theme.flex-column-centered;
		text-align: center;
		padding: 20px;
		@include theme.title;
		font-family: theme.$titleFont;
		font-size: 2.7rem;
		line-height: 3.5rem;
		width: 50%; // 50% is shared with .Actions so that together, they can only take up to 100%, hence never growing bigger than their container // we want to avoid that behaviour on small screens, hence a media query below
		@include theme.small-screen {
			padding: 0;
			width: 100%;
		}

		span {
			padding: 10px 0;
		}

		:nth-child(2) {
			@include theme.title-boxed;		
			line-height: 2.2rem;
		}
	}	

	.RatingContainer {
		@include theme.flex-column-centered;
		gap: theme.$cardContentGap;

		h2{
			@include theme.title-boxed;
		}
	}

	.Rating {
		margin: 0 auto;
		text-align: center;
		width: 50%; // 50% is shared with .Actions so that together, they can only take up to 100%, hence never growing bigger than their container // we want to avoid that behaviour on small screens, hence a media query below

		@include theme.small-screen {
			width: 70%;
		}

		img {
			cursor: pointer;
			margin: 0 15px;
		}

		.Icons {
			transition: all 0.15s;
			transform: scale(0.85);
			@include theme.flex-row;
			max-width: min(100%, 300px);
			justify-content: space-around;
			&:hover,
			&.Selected {
				transform: scale(1);
			}
			&.Bad {
				padding-top: 10px; // prevent an optical illusion making thumbs look like they're not aligned
				&.Selected {
					transform-origin: 0 50%;
					animation: rotateBadButtonSelected 1.5s 2 ease;
				}
			}
			&.Good {
				padding-bottom: 10px; // prevent an optical illusion making thumbs look like they're not aligned
				&.Selected {
					transform-origin: 0 50%;
					animation: rotateGoodButtonSelected 1.5s 2 ease;
				}
			}
		}
	}

	.RatingIcons {
		// container for both thumbs
		@include theme.flex-row-centered;
		min-width: 70%;
	}

	.Actions {
		@include theme.flex-column-centered;
		gap: theme.$cardContentGap;
		align-items: stretch;
		width: 50%; // 50% is shared with .Rating so that together, they can only take up to 100%, hence never growing bigger than their container
		@include theme.small-screen {
			margin: 0 auto;
			width: min(100%, 300px);
		}
	}

	.ActionLink {
		display: flex;
		justify-content: center;
		align-items: center;
		@include theme.text-gen(1rem);
		color: theme.$black;
		text-decoration: none;
		cursor: pointer;
		border-radius: theme.$cardCornerRadius;
		padding: 10px 15px;
		background-size: 100% 100%;
		border-color: theme.$secondaryDark;
		border-style: solid;
		border-width: 1.5px;
		width: -webkit-fill-available;
		width: -moz-available;
		&:hover {
			border-color: theme.$secondaryLight;
			box-shadow: theme.$lightShadow;
		}

		.ActionIcon {
			@include ActionIcon();
			margin-right: 10px;
		}

		button {
			border: none;
			background-color: theme.$white;
		}
		picture {
			position: relative;
			display: inline-flex;
			border-radius: 4px;

			img {
				@include ActionIcon();
				object-fit: cover;
				object-position: left 15%;
				border-radius: inherit;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 64px;
				height: 48px;
				background-image: theme.$secondaryGradient;
				opacity: 0.5;
				border-radius: inherit;
			}
		}

		svg {
			color: theme.$primaryDark;
			&:last-child {
				color: theme.$black;
			}
		}
	}
}

.ShareActions {
	@include theme.flex-row;

	.ActionLink {
		flex-direction: column;
		align-items: center;
		&:not(:nth-child(1)) {
			margin-left: 15px;
		}
	}

	// this is to remove "copy link" when on mobile - right now, this feature is not working
	@include theme.small-screen {
		:nth-child(3) {
			display: none;
		}
	}
}
