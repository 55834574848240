////////////////////////////////////////////////////
//  ------------------------------------------------
//  GLOBAL
//  ------------------------------------------------
////////////////////////////////////////////////////


//---------------------------------
//  MEDIA QUERIES
//---------------------------------
$largeScreenBreakpoint: 1200px;
$mediumScreenBreakpoint: 540px;
$smallScreenBreakpoint: 375px;
:export { 
	CSS_BREAKPOINT_LARGE: $largeScreenBreakpoint;
	CSS_BREAKPOINT_MEDIUM: $mediumScreenBreakpoint;
	CSS_BREAKPOINT_SMALL: $smallScreenBreakpoint;
 }
@mixin large-screen {
	// desktop
	@media (min-width: $largeScreenBreakpoint) {
		@content;
	}
}
@mixin medium-screen {
	// tablets
	@media (max-width: $largeScreenBreakpoint - 1px) {
		@content;
	}
}
@mixin small-screen {
	// mobile
	@media (max-width: $mediumScreenBreakpoint - 1px) {
		@content;
	}
}
@mixin min-small-screen {
	// mobile
	@media (min-width: $mediumScreenBreakpoint) {
		@content;
	}
}
@mixin extra-small-screen {
	// small mobile
	@media (max-width: $smallScreenBreakpoint - 1px) {
		@content;
	}
}

//---------------------------------
//  FLEX
//---------------------------------
@mixin flex-column {
	display: flex;
	flex-direction: column;
}

@mixin flex-row {
	display: flex;
	flex-direction: row;
}

@mixin flex-column-centered {
	@include flex-column;
	align-items: center;
	justify-content: center;
}

@mixin flex-column-left {
	@include flex-column;
	align-items: flex-start;
	justify-content: center;
}

@mixin flex-row-left {
	@include flex-row;
	align-items: center;
	justify-content: flex-start;
}

@mixin flex-row-centered {
	@include flex-row;
	align-items: center;
	justify-content: center;
}

@mixin flex-responsive-wrap {
	@include flex-row-centered;
	flex-wrap: wrap;
}

//---------------------------------
//  UTILS
//---------------------------------
@mixin hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

@function negative-value($val) {
	@return $val * -1;
}

//---------------------------------
//  SCROLLBAR
//---------------------------------
@mixin scroll-bar($maxHeight: 150px) {
	max-height: $maxHeight; // content scroll
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background-color: grey(0.15);
		border-radius: 3px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 3px;
		background: linear-gradient(90deg, $secondaryLight 0%, $secondaryDark 100%);
	}
}

//---------------------------------
//  PAGE
//---------------------------------
$pageMargin: 3vh 20px;
$headerFooterPadding: 0px;
$elementPadding: min(2vmin, 40px);
$elementSmallPadding: min(0.5vmin, 10px);

//---------------------------------
//  PLAYER
//---------------------------------
$playerSize: 4.1vh;
$playerHeight: $playerSize * 16;
$playerWidth: $playerSize * 9;
$playerBorderRadius: 25px;
$controlBarZindex: 1;
$controlBarHeightPx: 45px;
$controlBarHeightVh: 4vh;
$controlBarBorderRadius: 10px;
$delayBeforeNavAndVideoTransition: 1s;
$navAndVideoTransition: 1s ease;
$controlBarPaddingW: 1vh;