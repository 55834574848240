@use "src/theme.scss" as theme;

:global(.embedded){
	background: none;
	body{
		background: none !important;
	}
	:global(.kaltura-player-container){
		background-color:transparent;
	}
	:global(.playkit-poster){
		background-color:transparent;
		border-radius: 0;
	}
	:global(.playkit-engine){
		border-radius: 0;
	}
	:global(.playkit-video-player){
		background-color:transparent;
	}
	.playerContainer{
		width:calc((100vh - 6vh) / 16 * 9);
		height: calc(100vh - 6vh);
		border-radius: 0;
	}
	:global(#player){
		margin:0 auto;
		width: auto;
	}
}

:global(#player) {
	position: relative;
	@include theme.flex-column-centered;
	margin: 3vmin auto;
}

.playerContainer {
	height: theme.$playerHeight;
	width: theme.$playerWidth;
	border-radius: theme.$playerBorderRadius theme.$playerBorderRadius 0 0;
	position: relative;
	overflow: hidden;
}

.playerContainer > div {
	height: 100%;
	width: 100%;
}

.playerContainer > * {
	backface-visibility: hidden;
}

:global(.playkit-video-player){
	background-color:transparent !important;
}

:global(.playkit-poster) {
	border-radius: theme.$playerBorderRadius theme.$playerBorderRadius 0 0;
	background-image: url("./../../static/img/thumb.png");
	background-color:theme.$white;
}

:global(video) {
	border-radius: theme.$playerBorderRadius theme.$playerBorderRadius 0 0;
}

/* Remove kaltura player bottom bar, we have our own controls for that */
/* Remove kaltura player top gradient on pause (only visible on Safari?, was conflicting with rounded corners */
:global(.playkit-bottom-bar),
:global(.playkit-top-bar) {
	display: none;
}

:global(.playkit-black-cover),
:global(.kaltura-player-container) {
	background-color: transparent !important;
}

:global(.playkit-playback-controls.playkit-center-playback-controls) {
	top: 5% !important;
	left: 90% !important;
}

.EntireContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-radius: theme.$controlBarBorderRadius;
	align-self: center;
	position: relative;
	min-height: 83vh; // @TODO Better strategy for this magic number?
	transform-origin: bottom;
	transition: transform theme.$navAndVideoTransition;
	transition-delay: theme.$delayBeforeNavAndVideoTransition;
	/* if the video is playing OR in voting sequence */
	:global(body.playing) &,
	:global(body.voting) & {
		@include theme.small-screen {
			transform: scale(1.11);
		}
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.PulseSlowly {
	animation: pulse 2s 10;
}
