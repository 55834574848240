@use 'src/theme.scss' as theme;

$toggleFontSize: 1.25rem;

.ToggleButton {
	@include theme.flex-row-centered;
	background: theme.$white;
	border-radius: theme.$pillRadius;
	align-self: center;
	@media (prefers-color-scheme: dark){
		background: theme.$dmColorZ2;
	}

	&:focus {
		@include theme.shadow-focus();
	}

	.Option {
		@include theme.flex-row-centered;
		@include theme.title-gen(1rem);
		gap: 1rem;
		border: 2px solid theme.$secondaryLight;
		color: theme.$black;
		box-sizing: border-box;
		max-height: 40px;
		padding: 1rem 2rem;
		@media (prefers-color-scheme: dark){
			color: theme.$white;
			border-color: theme.$dmColorZ4;
		}
		

		&:first-of-type {
			border-right: none;
			border-radius: theme.$pillRadius 0 0 theme.$pillRadius;
		}
		&:not(:first-of-type):not(:last-of-type) {
			border-right: none;
			border-left: none;
		}
		&:last-of-type {
			border-left: none;
			border-radius: 0 theme.$pillRadius theme.$pillRadius 0;
		}
		&[aria-pressed='true'] {
			background: theme.$secondaryGradient;
			color: theme.$white;
			text-decoration: underline;
			@media (prefers-color-scheme: dark){
				background: theme.$dmColorZ4;
			}
		}
		&:not([aria-pressed='true']):hover {
			font-weight: 700;
			cursor: pointer;
		}
	}
}
