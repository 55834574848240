@use 'src/theme.scss' as theme;
@use "src/components/Common/Card.module.scss";

.TutorialCard {
	@extend .Card;
    @include theme.card-max-width(theme.$mediumCardWidth);

	.CardContent {
		@include theme.flex-column-centered;
		gap: theme.$cardContentGap;

		h2{
			@include theme.title-boxed;
		}
	}

	.LinesContainer {
		@include theme.flex-column-centered;
		gap: theme.$cardContentGap;
	}
}

.Section {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 90%;
	gap: 20px;

	& > svg {
		height: 100%;
		width: 100%;
		max-width: min(5vw, 40px);
		height: auto;
		margin: 0 1.5vw 0 1vw;
	}
}

.LineContent{
	@include theme.flex-column-left;
	text-align: left;
	gap: 5px;
}

.LineExplanation {
	@include theme.text-gen(1rem);
}