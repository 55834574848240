@import 'base';

@mixin card-max-width($max: 700px) {
	max-width: min(85%, $max);
	width: fit-content;
}

@mixin shadow-focus($grey: 0.8) {
	box-shadow: 0 2px 4px grey($grey);
	outline: none;
}


////////////////////////////////////////////////////
//  ------------------------------------------------
//  THEME VARS
//  ------------------------------------------------
////////////////////////////////////////////////////


//---------------------------------
//  COLORS
//---------------------------------
$transparencyHigh: 0.6;
$transparencyMed: 0.75;
$transparencyLow: 0.9;

/* GV-SunnyGradient */
$primaryDark: rgba(100, 195, 215, 1);
$primaryLight: rgba(100, 195, 215, 1);

/* LinearBlue */
$secondaryDark: rgba(229, 41, 29, 1);
$secondaryLight: rgba(229, 41, 29, 1);

/* Grays */
$grayDark: rgba(243, 243, 243, 1);
$grayLight: rgba(248, 246, 248, 1);

/* BG-DarkBlue Linear */
$tertiaryDark:rgb(100, 195, 215);
$tertiaryLight: rgba(100, 195, 215, $transparencyHigh);

// there is no linear gradient in the graphic charter of this brand, these gradients are solid colors because the scss rules of the components use the background-image property
$primaryGradient: linear-gradient(90deg, $primaryDark 0, $primaryDark 100%);
$secondaryGradient: linear-gradient(
	90deg,
	$secondaryLight 0,
	$secondaryLight 100%
);
$black: rgb(33,33,33);
$white: #fafafa;
@function grey($opacity: 1) {
	@return rgba(119, 119, 119, $opacity);
}
$mainColor: rgba(231, 81, 19, 1);
$errorColor: #FF7675;

$shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.25);
$lightShadow: 0px 1px 10px rgba(0, 0, 0, 0.15);


//---------------------------------
//  BACKGROUNDS
//---------------------------------
$pageBackgroundMobile:no-repeat url('/static/img/page-bkg.svg') calc(50% - 170px) center $white fixed;
$pageBackgroundDesktop:
	$white;

@mixin gradient-text-gen($color: $primaryGradient) {
	background: $color; // important: background color needs to be BEFORE the background-clip property :)
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-shadow: 0px 0px #00000000; // needed for Safari, otherwise gradient won't be displayed
}
$headerBackgound: rgb(16, 32, 49);

$cardHeaderBackground: $grayDark;
$cardContentBackground: $white;
$NavMobileBackground: $cardHeaderBackground;

// ROUNDED 'PILL' ELEMENT
$pillRadius: 50px;

// CARD
$cardCornerRadius: 8px;
$cardShadow: $shadow;
$cardSpacing: 3rem; // used for margins / paddings / gutter between Cards and their content
$smallCardSpacing: 2rem;

$smallCardWidth: 360px;
$mediumCardWidth: 500px;
$largeCardWidth: 700px;
$extraLargeCardWidth: 950px;

$cardContentGap: 20px;

//---------------------------------
//  FONTS
//---------------------------------
$titleFont: 'Montserrat', Helvetica, sans-serif;
$mainFont: 'Montserrat', Helvetica, sans-serif;
$secondaryFont: $mainFont, cursive;

@mixin title {
	font-weight: 400;
	line-height: normal;
}
@mixin title-gen($fontSize: 1.25rem, $fontWeight: 400) {
	@include title;
	line-height: normal;
	font-size: $fontSize;
	font-weight: $fontWeight;
}

@mixin text($color: $black) {
	font-family: $mainFont;
	line-height: normal;
	color: $color;
	@media (prefers-color-scheme: dark){
		color: $white;
	}
}

@mixin text-gen($fontSize: 8px, $fontWeight: 400) {
	font-family: $mainFont;
	line-height: normal;
	font-size: $fontSize;
	font-weight: $fontWeight;
	@media (prefers-color-scheme: dark){
		color: $white;
	}
}

@mixin title-boxed(){
	color: $secondaryDark;
	font-size: 2rem;
	font-weight: bold;
	background-color: $primaryDark;
	box-shadow: -3px 4px 5px rgba(0,0,0,0.3);
	transform: rotate(-3deg);
	padding:8px;
}

//---------------------------------
//  Dark mode
//---------------------------------
$dmColorZ0: #000000;
$dmColorZ1: #141414;
$dmColorZ2: #252525;
$dmColorZ3: #313131;
$dmColorZ4: #4b4b4b;

$dmPageBackgroundMobile:
	linear-gradient(170deg, $dmColorZ0 0, $dmColorZ1 600px);
$dmPgeBackgroundDesktop:
	linear-gradient(170deg, $dmColorZ0 0, $dmColorZ0 600px, $dmColorZ1 1200px);