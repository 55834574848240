@use 'src/theme.scss' as theme;

.Cta {
	position: relative;
	border: none;
	outline: none;
	border-radius: 28px;
	@include theme.title-gen(1.2rem);
	font-family:theme.$mainFont;
	text-align: center;
	box-sizing: border-box;
	min-height: 40px;
	padding: 16px 24px;
	background: theme.$secondaryGradient;
	color: theme.$white;
	cursor: pointer;
	display: inline-block;
	margin: 5px auto;
	@media (prefers-color-scheme: dark){
		background: theme.$dmColorZ4;
	}

	&.Secondary {
		background: transparent;
		color: theme.$secondaryDark;
		border: 2px inset theme.$secondaryLight;
		padding: 2px 38px 10px;
		@media (prefers-color-scheme: dark){
			color: theme.$white;
		}

		&:before {
			top: -2px;
			left: -2px;
			height: calc(100% + 4px);
			width: calc(100% + 4px);
		}
	}

	&:disabled {
		opacity: 0.7;
	}
	&.PulseSlowly {
		animation: pulse 2s infinite;
	}

}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(0.95);
	}
}
